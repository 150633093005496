<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Loading assessments
  </loading-spinner>
  <div v-else>
    <div class="
          text-center
          bg-white
          border
          py-3
          shadow-sm
          fst-italic
          text-muted
          small
          rounded
        " v-if="computedAssessments.length == 0">
      No assessment available
    </div>
    <div class="row g-3 mx-0" v-else>
      <assessment v-if="!isLoading && currentAssessment" :assessmentId="currentAssessment"
        :dependantId="$store.state.user._id" :minMarks="minMarks" type="tutor" @close="closeAssessment" />
      <div v-else class="
            col-12
            bg-white
            border
            rounded
            shadow-sm
            p-3
            d-flex
            justify-content-between
            align-items-center
            flex-column flex-lg-row
          " v-for="assessment in computedAssessments" :key="assessment._id">
        <div class="mb-3 mb-lg-0 text-center text-lg-start">
          <div class="
                d-flex
                flex-column flex-lg-row
                justify-content-center justify-content-lg-start
                align-items-center
                mb-2
              ">
            <span class="badge me-lg-2 mb-2 mb-lg-0" :class="`bg-${Helper.getLevelColor(assessment.level)}`"
              v-if="assessment.level">
              <small>{{ assessment.level }}</small>
            </span>
          </div>
          <p class="mb-0">
            {{ assessment.title }}
          </p>
        </div>
        <div class="d-flex align-items-center ps-3">
          <button @click="takeAssessment(assessment._id, true)"
            class="btn btn-sm btn-secondary text-light lh-sm me-2 text-nowrap"
            v-if="assessmentMarks(assessment._id) !== null">
            Papar Keputusan
            <i class="fas fa-eye ms-2" />
          </button>
          <button class="btn btn-sm text-light lh-sm me-2 text-nowrap" :class="assessmentMarks(assessment._id) !== null &&
              assessmentMarks(assessment._id) < minMarks
              ? 'bg-purple-dark'
              : 'bg-blue-dark'
            " v-if="assessmentMarks(assessment._id) < minMarks" @click="takeAssessment(assessment._id)">
            <span v-if="assessmentMarks(assessment._id) !== null &&
              assessmentMarks(assessment._id) < minMarks
              ">
              Jawab semula
            </span>
            <span v-else>Jawab</span>
            penilaian
            <i class="fas fa-caret-right ms-2" />
          </button>
          <span class="badge border fw-normal fs-6" :class="assessmentMarks(assessment._id) !== null
              ? assessmentMarks(assessment._id) >= minMarks
                ? 'bg-success'
                : 'bg-warning'
              : 'bg-light text-muted'
            ">
            {{ assessmentMarks(assessment._id) || 0 }}%
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Swal from "sweetalert2";
import Assessment from "@/components/Assessment";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  components: {
    LoadingSpinner,
    Assessment,
  },
  data() {
    return {
      isLoading: false,
      assessments: [],
      userAssessments: [],
      minMarks: 80,
      currentAssessment: null,
    };
  },
  computed: {
    computedAssessments() {
      const assessments = JSON.parse(JSON.stringify(this.assessments));

      return assessments.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
    assessmentMarks() {
      return (assessmentId) => {
        const assessment = this.userAssessments.find(
          (ua) => ua.assessment && ua.assessment._id == assessmentId
        );

        return assessment ? assessment.marks || 0 : null;
      };
    },
  },
  methods: {
    closeAssessment(e) {
      window.scrollTo(0, 0);

      if (e && e.data) {
        let assessmentIndex = this.userAssessments.findIndex(
          (ua) =>
            ua.assessment &&
            ua.assessment._id == e.data.assessment
        );

        e.data.assessment = { _id: e.data.assessment };

        if (assessmentIndex > -1) {
          this.userAssessments[assessmentIndex] = e.data;
        } else {
          this.userAssessments.push(e.data);
        }
      }

      this.currentAssessment = null;
    },
    takeAssessment(assessmentId, readOnly) {
      const assessment = this.assessments.find((ca) => ca._id == assessmentId);

      if (!assessmentId || !assessment) {
        return;
      }

      const userAssessment = this.userAssessments.find(
        (ua) => ua.assessment && ua.assessment._id == assessmentId
      );

      if (readOnly) {
        this.currentAssessment = JSON.stringify({
          id: assessmentId,
          userAssessment: userAssessment
            ? userAssessment._id
            : null,
          assessmentResult: userAssessment
            ? userAssessment.answers
            : null,
          assessmentMarks: userAssessment
            ? userAssessment.marks
            : null,
        });
      } else {
        Swal.fire({
          title: `<h5 class="mb-0">${userAssessment ? "Jawab semula" : "Jawab"
            } penilaian ini?</h5>`,
          html: `
            <p class="small bg-light rounded border shadow-sm p-3 text-dark">${assessment.title}</p>
            <p class="mb-0"></p>
          `,
          icon: "question",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            this.currentAssessment = JSON.stringify({
              id: assessmentId,
              dependantAssessment: userAssessment ? userAssessment._id : null,
            });
          }
        });
      }
    },
    async getAssessments() {
      return new Promise(async (resolve) => {
        try {
          let url = `assessments?_limit=-1&_sort=date:DESC`;

          const { data } = await this.API.get(url);

          this.assessments = data;
        } catch (error) {
          console.error(error);
        } finally {
          resolve();
        }
      });
    },
    async getUserAssessments() {
      return new Promise(async (resolve) => {
        try {
          let url = `teacher-assessments?_limit=-1&user=${this.$store.state.user._id}&_sort=date:DESC`;

          const { data } = await this.API.get(url);

          this.userAssessments = data;
        } catch (error) {
          console.error(error);
        } finally {
          resolve();
        }
      });
    },
  },
  async mounted() {
    let waitUserAvailable = async () => {
      this.isLoading = true;

      if (this.$store.state.user) {
        await this.getUserAssessments();

        await this.getAssessments();

        this.isLoading = false;
      } else {
        setTimeout(() => {
          waitUserAvailable();
        }, 1000);
      }
    };

    waitUserAvailable();
  },
};
</script>